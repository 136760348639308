import React, { useEffect, useState } from "react";
import { Table, Input, Button, Space, Card, notification, Spin, Typography } from "antd";
import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../../backend/db"; // Import Firebase Firestore instance
import initialData from "../../../assets/programs/other_programs.json"; // Fallback local JSON

const { Title } = Typography;

// uses smart edit table json
const EditPrograms = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data from Firebase (or fallback to local JSON if it fails)
  useEffect(() => {
    const fetchDataFromFirebase = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "mass_programs"));
        if (querySnapshot.empty) {
          setDataSource(initialData); // Load local JSON if no data in Firestore
          notification.info({
            message: "No Data in Firebase",
            description: "Loaded local data as fallback.",
          });
        } else {
          const data = querySnapshot.docs.map((doc) => doc.data());
          setDataSource(data);
        }
      } catch (error) {
        console.error("Error loading data from Firebase:", error);
        setDataSource(initialData);
        notification.warning({
          message: "Firebase Load Failed",
          description: "Loaded local data as fallback.",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchDataFromFirebase();
  }, []);

  // Save the edited data back to Firebase using program name as document ID
  const saveDataToFirebase = async () => {
    setLoading(true);
    try {
      for (let idx = 0; idx < dataSource.length; idx++) {
        const data = dataSource[idx];
        const programName = data.program.replace(/\s+/g, "-").toLowerCase(); // Format program name as document ID
        const docRef = doc(collection(db, "mass_programs"), programName);
        await setDoc(docRef, data);
      }

      notification.success({
        message: "Save Successful",
        description: "Data has been saved to Firebase!",
      });
    } catch (error) {
      console.error("Error saving data:", error);
      notification.error({
        message: "Save Failed",
        description: "Could not save data to Firebase.",
      });
    } finally {
      setLoading(false);
    }
  };

  // Render array items as multiple input fields with delete and add features
  const renderArray = (value, key, record) => (
    <Space direction="vertical" style={{ width: "100%" }}>
      {value.map((item, idx) => (
        <div key={`${key}-${idx}`} style={{ display: "flex", alignItems: "center" }}>
          <Input.TextArea
            autoSize
            value={item}
            onChange={(e) => {
              const updatedArray = [...value];
              updatedArray[idx] = e.target.value;
              handleEdit(updatedArray, key, key, record);
            }}
            style={{ width: "100%" }}
          />
          <Button
          type="link"
            style={{ padding: 0, margin: 0 }}
            icon={<DeleteOutlined/>}
            danger
            onClick={() => {
              const updatedArray = value.filter((_, index) => index !== idx);
              handleEdit(updatedArray, key, key, record);
            }}
          />
        </div>
      ))}
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        onClick={() => {
          const updatedArray = [...value, ""];
          handleEdit(updatedArray, key, key, record);
        }}
        style={{ width: "100%" }}
      >
        Add New Item
      </Button>
    </Space>
  );

  // Recursive handler for rendering and editing objects
  const renderObject = (value, parentKey, record) => (
    <Card style={{ paddingLeft: "20px", marginBottom: "10px" }}>
      {Object.keys(value).map((subKey) => (
        <Space key={subKey} direction="vertical" style={{ width: "100%" }}>
          {typeof value[subKey] === "object" && !Array.isArray(value[subKey]) ? (
            renderObject(value[subKey], `${parentKey}.${subKey}`, record)
          ) : (
            <Input
              addonBefore={subKey}
              value={value[subKey]}
              onChange={(e) => {
                const updatedObject = { ...value, [subKey]: e.target.value };
                handleNestedObjectEdit(updatedObject, parentKey, record);
              }}
            />
          )}
        </Space>
      ))}
    </Card>
  );

  // Helper function for editing nested objects
  const handleNestedObjectEdit = (updatedObject, parentKey, record) => {
    const newData = dataSource.map((item) => {
      if (item.program === record.program) {
        const keys = parentKey.split(".");
        let temp = { ...item };

        keys.reduce((acc, key, index) => {
          if (index === keys.length - 1) {
            acc[key] = updatedObject; // Final replacement
          }
          return acc[key];
        }, temp);

        return temp;
      }
      return item;
    });
    setDataSource(newData);
  };

  // Handles updating the table data when editing fields
  const handleEdit = (value, key, dataIndex, record) => {
    const updatedData = dataSource.map((item) => {
      if (item.program === record.program) {
        return { ...item, [dataIndex]: value };
      }
      return item;
    });
    setDataSource(updatedData);
  };

  // Automatically detect columns and handle different data types
  const generateColumns = () => {
    if (!dataSource || dataSource.length === 0) return [];

    return ["program", "description", "benefits", "contact_info", "links_to_apply", "eligibility_criteria"].map((key) => ({
      title: key.charAt(0).toUpperCase() + key.slice(1),
      dataIndex: key,
      width: 400,
      key,
      render: (value, record) => {
        if (Array.isArray(value)) {
          return renderArray(value, key, record); // Handle array of strings
        } else if (typeof value === "object" && value !== null) {
          return renderObject(value, key, record); // Handle object (recursively)
        } else {
          return <Input.TextArea autoSize value={value} onChange={(e) => handleEdit(e.target.value, key, key, record)} />; // Handle strings
        }
      },
    }));
  };

  return (
    <div style={{ padding: "20px" }}>
      <Title level={3}>Massachusetts Programs Editor</Title>
      <Typography.Paragraph >Please make the changes carefully and click save changes to firebase below.</Typography.Paragraph>
      {loading ? (
        <Spin size="large" />
      ) : (
        <>
          <Table
            bordered
            scroll={{  x: 'max-content' }} // Set sticky header and auto width
   
            dataSource={dataSource}
            columns={generateColumns()}
            rowKey="program"
            pagination={false}
            expandable={{
              expandedRowRender: (record) => <pre>{JSON.stringify(record, null, 2)}</pre>,
            }}
            style={{ marginBottom: "20px" }}
          />
          <Button type="primary" icon={<SaveOutlined />} onClick={saveDataToFirebase} disabled={loading} style={{ marginTop: "20px" }}>
            Save Changes to Firebase
          </Button>
        </>
      )}
    </div>
  );
};

export default EditPrograms;
