import "./backend/db.js";
import React from "react";
import { ChatBotComponent } from "./Chatbot.js";
import AppRoutes from "./routes";
import "./App.css";
import LanguageSelection, { LanguageSelectionButton } from "./Gtranslate.js";
function App() {
  return (
    <div className="App">
      <AppRoutes />
      {/* <LanguageSelectionButton triggerBtn={false} /> */}
      <ChatBotComponent />
    </div>
  );
}

export default App;
