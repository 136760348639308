export function highlightElement(element, duration = 1000) {
  if (element) {
    console.log("Highlighting element", element);

    // Store original colors
    const elements = element.querySelectorAll("*");
    const originalColors = [];

    // Apply dark grey color and store original colors
    elements.forEach((child) => {
      originalColors.push(child.style.backgroundColor);
      child.style.transition = `background-color ${duration}ms ease-out`;
      child.style.backgroundColor = "darkgrey";
    });

    // Reset to original colors after duration
    setTimeout(() => {
      elements.forEach((child, index) => {
        child.style.backgroundColor = originalColors[index] || "";
      });
    }, duration);
  } else {
    console.log("Element not found", element);
  }
}
