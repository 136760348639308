import { collection, getDocs, query, where, doc, getDoc } from "firebase/firestore";
import { auth, db } from "../db";


export async function getUserById(userId) {
  const userDocRef = doc(db, "UserTest", userId);
  const docSnap = await getDoc(userDocRef);

  if (docSnap.exists()) {
    const user = { id: docSnap.id, ...docSnap.data() };
    console.log("User found: ", user)
    return user;
  }
}
export async function getAllUsers(collectionName="UserTest") {
  const usersCollectionRef = collection(db, collectionName);
  const querySnapshot = await getDocs(usersCollectionRef);
  
  const users = querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data()
  }));

  console.log("All users: ", users);
  return users;
}