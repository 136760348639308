// digitalFormConfig.js

import { Modal } from "antd";
import fields from "../../fields";
import FccStatements from "./fccstatements";

const formName = "lifeline-digital";

export const digitalFormConfig = [
  { type: "heading", title: "Lifeline Digital Application" },
  fields.first_name,
  fields.last_name,
  fields.dob,
  fields.preferred_language,
  fields.ssn_last4,
  fields.address,
  {
    title: "Apartment number, Unit number, House number",
    id: "apartment_number",
    required: false,
    type: "text",
    info: "Please enter your apartment number, unit number, or house number.",
  },
  fields.city,
  fields.zip,
  fields.gender,
  fields.race,
  fields.email,
  fields.phone_number,
  {
    title: "How do you qualify?",
    id: "qualification",
    required: true,
    type: "checkbox",
    options: [
      { value: "SNAP", text: "SNAP" },
      { value: "Medicaid", text: "Medicaid" },
      { value: "Medicare", text: "Medicare" },
    ],
  },
  {
    title: (
      <div style={{display:"inline"}}>
        Read these
        <span
          className="link"
          onClick={() => {
            Modal.info({
              width: "100%",
              height: "500px",
              closable: true,
              maskClosable: true,
              closeIcon: true,
              title: "FCC Statements",
              content: <FccStatements />,
              onOk() {},
            });
          }}
        >
          {" "}10 statements{" "}
        </span>
        provided by the Federal Communications Commission (FCC) which supports the Lifeline Benefit Program. After reading, please check yes or no if you can agree with the following statements.
      </div>
    ),
    id: "fcc_statements",
    required: true,
    type: "radio",
    options: [
      { value: "Yes", text: "Yes" },
      { value: "No", text: "No" },
    ],
  },
  {
    title: "Do you consent to receiving Lifeline Program sign-up navigation from the Link Health team?",
    id: "consent_lifeline_navigation",
    required: true,
    type: "radio",
    options: [
      { value: "Yes", text: "Yes" },
      { value: "No", text: "No" },
    ],
  },
  fields.consent(formName),
];
