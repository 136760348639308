// lifelineFormConfig.js

import fields from '../../fields';
export const formName="wic-mass"
export const wicMassConfig = [
  { type: "heading", title: "WIC Application" },
  fields.first_name,
  fields.last_name,
  fields.dob,
  fields.address,
  fields.city,
  fields.zip,
  fields.email,
  fields.phone_number,
  {
    title: "Preferred Language",
    id: "preferred_language-wic",
    required: true,
    type: "select",
    options: [
      { value: "English", text: "English" },
      { value: "Spanish", text: "Spanish" },
      { value: "Cantonese", text: "Cantonese" },
      { value: "Mandarin", text: "Mandarin" },
      { value: "French", text: "French" },
      { value: "Portuguese", text: "Portuguese" },
      { value: "Russian", text: "Russian" },
      { value: "Arabic", text: "Arabic" },
      { value: "Haitian Creole", text: "Haitian Creole" },
      { value: "Other", text: "Other" },
    ],
  },
  {
    title: "Best time to contact you",
    id: "best_time_to_contact",
    required: true,
    type: "checkbox",
    options: [
      { value: "morning", text: "Morning" },
      { value: "afternoon", text: "Afternoon" },
      { value: "late_afternoon", text: "Late Afternoon" },
      { value: "evening", text: "Evening" },
      { value: "saturday_morning", text: "Saturday Morning" },
    ],
  },  
  fields.consent(formName),
];
