import React, { useState } from "react";
import {
  Button,
  Modal,
  Table,
  Typography,
  Input,
  Space,
  Alert,
  message,
  Menu,
  Dropdown,
  Divider,
} from "antd";
import moment from "moment";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
} from "recharts";
import formsConfig, { formsConfigWithKey } from "../../../form/config";
import Loading from "../../../../components/ui/loading/Loading";
import { dateNumberToAmericanTime } from "../../../../helpers/time";
import { createUserInMainDB } from "../../../../backend/users/set_main";
import { updateUser } from "../../../../backend/users/set";
import axios from "axios";
import AISpamResponsePrint, {
  parseAISpamResponseData,
} from "./AISpamDetectionResponse";
import UserInfo from "./UserInfo";
import { tableIcons } from "./static";
import ViewChatSavedOnServer from "./ViewChat";

const aiSpamDetectionInstructions = `You are a spam (fake) application detector. Analyze the following application data to determine whether it is Fake or Real. Focus on verifying obvious elements such as personal details. Ignore any data or fields that you do not understand or are ambiguous. If you think the application is very very fake, classify it as "Fake"; otherwise, classify it as "Real". Don't be very strict in checking. Return your analysis in JSON format with the following fields:

classification: "Fake" or "Real"
fakeness_level: 0-100
realness_level: 0-100
comments: Provide specific reasons for your classification.`;

const { Text } = Typography;
const { Search } = Input;

const displayUserMoreInfo = (type, user, form, rawUsers) => {
  const rawUser = rawUsers.find(
    (u) => u.id === "raw-" + user.id + "-" + form.key
  );
  Modal.info({
    title: "More information",
    content: (
      <>
        Form started on:{" "}
        {dateNumberToAmericanTime(rawUser?.[form.key + "-started_on"])}
        <br />
        Last saved on:{" "}
        {dateNumberToAmericanTime(rawUser?.[form.key + "-last_saved_on"])}
      </>
    ),
  });
};

const renderUserFormOption = (user, form, rawUsers) => {
  if (user[form.key + "_submitted"]) {
    return tableIcons.FormSubmitted;
  }
  if (rawUsers) {
    const rawUser = rawUsers.find(
      (u) => u.id === "raw-" + user.id + "-" + form.key
    );
    if (rawUser?.[form.key + "-last_saved_on"])
      return (
        <Button
          onClick={() =>
            displayUserMoreInfo("form_started", user, form, rawUsers)
          }
          type="link"
        >
          {tableIcons.FormStarted}
        </Button>
      );
  }
  if (user[form.key + "-started_on"]) {
    return tableIcons.FormOpened;
  }
};

// Aggregate form statuses for chart data
const getStatusCounts = (users, formsConfigWithKey) => {
  return formsConfigWithKey.map((form) => {
    let formStatusCounts = {
      name: form.title,
      FormSubmitted: 0,
      FormStarted: 0,
      FormOpened: 0,
    };

    users.forEach((user) => {
      if (user[form.key + "_submitted"]) formStatusCounts.FormSubmitted++;
      else if (user[form.key + "-started_on"]) formStatusCounts.FormStarted++;
    });

    return formStatusCounts;
  });
};

// Stacked form chart component
const StackedFormChart = ({ chartData }) => (
  <BarChart width={700} height={350} data={chartData}>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Bar dataKey="FormSubmitted" name={"Submitted"} stackId="a" fill="green" />
    <Bar dataKey="FormStarted" name={"UnSubmitted"} stackId="a" fill="orange" />
  </BarChart>
);

// Line chart data preparation for submissions over the last 30 days
const getSubmissionsOverTime = (users) => {
  const last30Days = Array.from({ length: 30 }, (_, i) =>
    moment().subtract(i, "days").format("YYYY-MM-DD")
  ).reverse();

  const submissionData = last30Days.map((date) => {
    const count = users.filter(
      (user) => moment(user.createdOn).format("YYYY-MM-DD") === date
    ).length;
    return { date, submissions: count };
  });

  return submissionData;
};

// Line chart component
const SubmissionLineChart = ({ data }) => (
  <LineChart width={700} height={350} data={data}>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="date" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Line type="monotone" dataKey="submissions" stroke="#8884d8" />
  </LineChart>
);

const UserTableWithCharts = ({ users, rawUsers }) => {
  const [searchText, setSearchText] = useState("");

  if (!users) return <Loading />;

  const handleSearch = (value) => {
    setSearchText(value.toLowerCase());
  };

  const filteredUsers = users
    .filter(
      (user) =>
        (user.name && user.name.toLowerCase().includes(searchText)) ||
        (user.first_name && user.first_name.toLowerCase().includes(searchText))
    )
    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
  const handleSyncWithMain = async (user) => {
    createUserInMainDB(
      {
        ...user,
        phone: user.phone_number,
        application_type: user?.qualification.join(", "),
      },
      user?.id
    ).then((res) => {
      message.info("user synced in main db");
    });
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (createdOn) => <Text>{moment(createdOn).format("DD MMM")}</Text>,
      sorter: (a, b) => new Date(a.createdOn) - new Date(b.createdOn),
      // defaultSortOrder: 'descend', // Automatically sort by descending date order
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => <Text ellipsis>{text}</Text>,
    },
    {
      title: "Name",
      width: "200px",
      key: "name",
      render: (user) => {
        const name = user.name || user.first_name || "";
        return (
          <Text strong ellipsis>
            {name}
          </Text>
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (text) => <Text ellipsis>{text}</Text>,
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      render: (text) => <Text ellipsis>{text}</Text>,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (text) => <Text ellipsis>{text}</Text>,
    },
    {
      title: "Zip Code",
      dataIndex: "zip",
      key: "zip",
      render: (text) => <Text ellipsis>{text}</Text>,
    },
    {
      title: "Spam Risk",
      dataIndex: "aispam_spam_risk",
      key: "zip",
      render: (text) => (
        <Text style={{ color: text === "high" ? "red" : "green" }}>{text}</Text>
      ),
    },
    ...formsConfigWithKey.map((form) => ({
      title: form.title,
      key: form.key,
      render: (user) => renderUserFormOption(user, form, rawUsers),
    })),
    {
      title: "Options",
      key: "options",
      render: (user) => {
        const actions = {
          chatIds: () => {
            Modal.info({
              title: "Chat IDs",
              content: (
                <>
                  Please view these chats on app.plucky.ai
                  <br />
                  {user.chat_ids.map((id, index) => (
                    <div style={{ marginBottom: "5px" }} key={id}>
                      {index + 1}: {id}
                    </div>
                  ))}
                  <ViewChatSavedOnServer chatIds={user.chat_ids ?? []} />
                </>
              ),
            });
          },
          syncWithMain: async () => {
            await handleSyncWithMain(user);
          },
          aiSpamDetection: async () => {
            axios
              .post(
                `${process.env.REACT_APP_CHAT_BACKEND_BASE_URL}simple_ai_query`,
                {
                  instructions: aiSpamDetectionInstructions,
                  query: JSON.stringify(user),
                }
              )
              .then((res) => {
                const response = res.data;
                Modal.info({
                  title: "AI spam result",
                  content: (
                    <>
                      <AISpamResponsePrint response={response} />
                    </>
                  ),
                });
                updateUser(user.id, {
                  ...parseAISpamResponseData(response),
                }).then((res) => {
                  message.info("application information updated");
                });
              })
              .catch((error) => {
                console.error("Error:", error);
              });
          },
        };

        // Configure the dropdown menu with defined actions
        const menu = (
          <Menu>
            {/* <Menu.Item key="aiSpamDetection" onClick={actions.aiSpamDetection}>
              Detect SPAM with AI
            </Menu.Item> */}
            <Menu.Item key="chatIds" onClick={actions.chatIds}>
              Chat
            </Menu.Item>
            {/* <Menu.Item key="syncWithMain" onClick={actions.syncWithMain}>
              Sync
              <br />
              <small style={{ color: "grey" }}>with main dashboard</small>
            </Menu.Item> */}
          </Menu>
        );

        // Render the Dropdown component
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button type="primary">Options</Button>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const chartData = getStatusCounts(filteredUsers, formsConfigWithKey);
  const submissionData = getSubmissionsOverTime(filteredUsers);

  return (
    <div style={{ padding: "20px" }}>
      <Alert
        showIcon
        style={{ padding: "10px", marginBottom: "20px" }}
        message={`Total unique user sessions=${users.length}, you are only viewing users having submissions`}
      />
      <Space style={{ marginBottom: 16 }}>
        <Search
          style={{ width: "30vw" }}
          placeholder="Search by Name"
          allowClear
          enterButton="Search"
          size="large"
          onSearch={handleSearch}
        />
      </Space>
      <Table
        expandable={{
          expandedRowRender: (record) => <UserInfo user={record} />,
        }}
        columns={columns}
        dataSource={filteredUsers}
        pagination={false}
        rowKey="id"
        bordered
        size="middle"
        style={{ borderRadius: "10px", marginBottom: "40px" }}
      />
      <Space style={{ marginTop: "100px" }}>
        <StackedFormChart chartData={chartData} />
        <SubmissionLineChart data={submissionData} />
      </Space>
    </div>
  );
};

export default UserTableWithCharts;
