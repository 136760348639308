import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { getAllParamsFromLocation } from "./helpers/loc";

const root = ReactDOM.createRoot(document.getElementById("root"));


// setting the source

if(getAllParamsFromLocation()["source"]){
  localStorage.setItem("entrance_source",getAllParamsFromLocation()["source"])
}


root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#47B0F8",
        },
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

