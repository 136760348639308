import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FormIndex from "../pages/form";
import Home from "../Home";
import AdminDashboard from "../pages/Admin/dashboard/AdminDashboard";
import EditPrograms from "../pages/Admin/EditPrograms/EditPrograms";
import { AuthProvider } from "./AuthContext";
import RequireAuth from "./RequireAuth";
import AdminLogin from "../pages/Auth/Login";

// Define the array of routes
export const routeMap = [
  {
    path: "/",
    element: <Home />,
    isProtected: false,
  },
  {
    path: "/form/:id",
    element: <FormIndex />,
    isProtected: false,
  },
  {
    path: "/admin/login",
    element: <AdminLogin />,
    isProtected: false,
  },
  {
    path: "/admin/dashboard",
    element: <AdminDashboard />,
    isProtected: true,
  },
  {
    path: "/admin/edit",
    element: <EditPrograms />,
    isProtected: true,
  },
];

const isEnrollDomain = window.location.hostname === "enroll.link-health.org"; // no need to render isProtectedRoutes or auth checking if isEnrollDomain to make application faster.

const AppRoutes = () => {
  return (
    <Router>
      {isEnrollDomain ? (
        <Routes>
          {routeMap
            .filter((route) => !route.isProtected)
            .map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
        </Routes>
      ) : (
        <AuthProvider>
          <Routes>
            {routeMap.map((route, index) => {
              if (route.isProtected) {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={<RequireAuth>{route.element}</RequireAuth>}
                  />
                );
              } else {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={route.element}
                  />
                );
              }
            })}
          </Routes>
        </AuthProvider>
      )}
    </Router>
  );
};

export default AppRoutes;
