import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../db";
import { cleanObject } from "../../helpers/obj";

export async function createUser(userData, userId = null) {
  const userCollectionRef = collection(db, "UserTest");
  const savedAmbassadorName = localStorage.getItem("ambassadorName") || "-";
  const entrance_source = localStorage.getItem("entrance_source") || "-";

  let docRef;

  if (userId) {
    // If userId is provided, update the existing document with that ID
    const existingDocRef = doc(userCollectionRef, userId);
    await setDoc(existingDocRef, { ...userData, ambassadorName: savedAmbassadorName, entrance_source }, { merge: true });
    docRef = existingDocRef;
  } else {
    // If no userId is provided, create a new document
    docRef = await addDoc(userCollectionRef, { ...userData, ambassadorName: savedAmbassadorName, entrance_source });
  }

  console.log("Document written with ID: ", docRef.id);
  return docRef.id;
}

const getLoc = () => {
  const currentUrl = window.location.href;
  if (currentUrl.includes("tx-")) {
    return "tx";
  } else {
    return "ma";
  }
};

export async function updateUser(userId, updatedUserData, where = "UserTest") {
  const loc = getLoc();
  const userDocRef = doc(db, where, userId);

  try {
    await updateDoc(userDocRef, cleanObject({ ...updatedUserData, loc }));
    console.log("Document updated");
  } catch (error) {
    if (error.code === "not-found") {
      // If no document exists, create a new one
      await setDoc(userDocRef, cleanObject({ ...updatedUserData, loc }));
      console.log("Document created");
    } else {
      console.error("Error updating/creating document:", error);
    }
  }
}

/**
 * RUN UPDATE ALL
 *
 */

// setTimeout(() => {
//   const to_update = { loc: "ma" };
//   getAllUsers().then((users) => {
//     users?.forEach(async (user) => {
//       console.log("updating user", user);
//       await updateUser(user?.id, { ...to_update, ...user });
//     });
//   });
// }, 2000);
