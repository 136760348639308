import { addDoc, collection, doc, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../db";

function cleanObject(obj) {
  // Check if the value is of an acceptable type
  const isAcceptableType = (value) => typeof value === "string" || typeof value === "number" || Array.isArray(value) || (typeof value === "object" && value !== null);

  // Iterate over object properties
  for (const key in obj) {
    const value = obj[key];

    // Recursively clean if the value is an object
    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      cleanObject(value);
    }

    // Delete property if not of an acceptable type or is null/undefined
    if (!isAcceptableType(value)) {
      delete obj[key];
    }
  }

  return obj;
}

export async function createUserInMainDB(userDataBad, userId = null) {
  const userData = cleanObject(userDataBad);
  const userCollectionRef = collection(db, "User");
  const savedAmbassadorName = localStorage.getItem("ambassadorName") || "-";
  let docRef;

  if (userId) {
    // If userId is provided, update the existing document with that ID
    const existingDocRef = doc(userCollectionRef, userId);
    await setDoc(existingDocRef, { ...userData, ambassadorName: savedAmbassadorName, createdOn: new Date().getTime() }, { merge: true });
    docRef = existingDocRef;
  } else {
    // If no userId is provided, create a new document
    docRef = await addDoc(userCollectionRef, { ...userData, ambassadorName: savedAmbassadorName, createdOn: new Date().getTime() });
  }

  console.log("Document written with ID: ", docRef.id);
  return docRef.id;
}
