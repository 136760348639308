export const config = {
  newMessage: `
# BASIC_RESPONSE_RSWVKCLAMSKN

Hi! My name is Leo. I help people access **$80 billion** of government assistance that goes unclaimed every year. 

I can assist with programs like **Massachusetts SNAP**, **WIC**, **Lifeline**, and many other benefits.

# BASIC_BUTTON_RSWVKCLAMSKN
1. Start assistance
`,
  errorMessage: "Something went wrong, please try again. Or send us a text message at ...",
  assistantID: "asst_AIDxKFljVPpoesbpwkaXg6Gb",
  messages: {
    1: "Thank you for completing the form!! 😊",
    2: "You have not completed the form yet !! Is there any other way I can help you with?",
  },
  linkParser: [{ "/form/digital": "" }],
};
