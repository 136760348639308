import React, { useState } from "react";
import { Modal, Button, Typography, Divider } from "antd";
import axios from "axios";

const { Text } = Typography;

const ViewChatSavedOnServer = ({ chatIds }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchChatData = async () => {
    setLoading(true);
    try {
      const requests = chatIds.map((id) =>
        axios.get(`${process.env.REACT_APP_CHAT_BACKEND_BASE_URL}history`, {
          params: {
            threadID: id,
            limit: "50",
            apiKey: "None",
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
      );

      const responses = await Promise.all(requests);
      const data = responses.map((response, index) => ({
        chatId: chatIds[index],
        history: response.data.history,
      }));

      setChatData(data);
    } catch (error) {
      console.error("Error fetching chat data:", error);
    } finally {
      setLoading(false);
    }
  };

  const showModal = async () => {
    await fetchChatData();
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal} loading={loading}>
        View Chat History
      </Button>
      <Modal
        title="Chat History"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        {chatData.map((chat) => (
          <div key={chat.chatId} style={{ marginBottom: "20px" }}>
            <Text strong style={{ fontSize: "16px" }}>
              Chat ID: {chat.chatId}
            </Text>
            <Divider />
            {chat.history.map((message, index) =>
              message.content?.includes("Objective:") ? (
                ""
              ) : (
                <div
                  key={index}
                  style={{
                    padding: "10px",
                    marginBottom: "10px",
                    background:
                      message.role === "assistant"
                        ? "#e6f7ff"
                        : message.role === "user"
                        ? "#fffbe6"
                        : "#f0f0f0",
                    borderRadius: "5px",
                  }}
                >
                  <Text strong>
                    {message.role.charAt(0).toUpperCase() +
                      message.role.slice(1)}
                    :
                  </Text>
                  <div style={{ marginTop: "5px", whiteSpace: "pre-wrap" }}>
                    {message.content}
                  </div>
                </div>
              )
            )}
          </div>
        ))}
      </Modal>
    </>
  );
};

export default ViewChatSavedOnServer;
