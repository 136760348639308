// lifelineFormConfig.js

import { allRelations } from "../../_help/relations";
import fields from "../../fields";
export const formName = "snap";
export const snapArizonaConfig = [
  { type: "heading", title: "Personal Information" },
  fields.first_name,
  fields.last_name,
  fields.gender,
  fields.dob,
  fields.preferred_language,
  fields.email,
  fields.phone_number,
  fields.address,
  fields.city,
  fields.zip,
  fields.race,
  {
    title: "Ethnicity:",
    id: "ethnicity",
    required: true,
    type: "select",
    options: [
      { value: "Hispanic or Latino", text: "Hispanic or Latino" },
      { value: "Not Hispanic or Latino", text: "Not Hispanic or Latino" },
    ],
  },
  {
    title: "Are you homeless?",
    id: "homeless",
    required: true,
    type: "radio",
    options: [
      { value: "Yes", text: "Yes" },
      { value: "No", text: "No" },
    ],
  },
  {
    title: "Disability: Select your status from the dropdown menu",
    id: "disability",
    required: true,
    type: "select",
    options: [
      { value: "Physical", text: "Physical" },
      { value: "Hearing", text: "Hearing" },
      { value: "Visual", text: "Visual" },
      { value: "Intellectual/Cognitive", text: "Intellectual/Cognitive" },
      { value: "Emotional/Mental Health", text: "Emotional/Mental Health" },
      { value: "None of the above", text: "None of the above" },
    ],
  },
  {
    title: "Has Massachusetts certified that you have a disability?",
    id: "disability_certified",
    required: true,
    type: "radio",
    options: [
      { value: "Yes", text: "Yes" },
      { value: "No", text: "No" },
    ],
  },
  {
    title:
      "Do you have a Massachusetts Electronic Benefit Transfer (EBT) card?",
    id: "massachusetts_ebt",
    required: true,
    type: "radio",
    options: [
      { value: "Yes", text: "Yes" },
      { value: "No", text: "No" },
    ],
  },
  {
    title: "What is your preferred method of communication?",
    id: "preferred_communication",
    required: true,
    type: "select",
    options: [
      { value: "In-Person", text: "In-Person" },
      { value: "Telephone", text: "Telephone" },
      {
        value: "Video Replay Services (VRS)",
        text: "Video Replay Services (VRS)",
      },
    ],
  },
  {
    title: "Are you pregnant?",
    id: "pregnant",
    required: true,
    type: "radio",
    options: [
      { value: "Yes", text: "Yes" },
      { value: "No", text: "No" },
    ],
  },
  {
    title: "Social Security Number (SSN)",
    id: "ssn",
    required: true,
    type: "text",
    help_text2:
      "Privacy Notice: Your SSN is never stored and is sent directly to the state.",
  },
  { title: "Family information", type: "heading" },

  {
    id: "live_with_others",
    title: "Do other people live with you?",
    snap_id: "Do you live with other people?",
    type: "boolean",
    value: false,
    more: {
      // ask more questions when the answer of the above question is= when
      when: true,
      limit: 4,
      questions: [
        {
          id: "name",
          title: "Name",
          type: "text",
          snap_id: (index) => {
            return `Household member name${index + 1}`;
          },
        },
        {
          id: "date_of_birth",
          title: "Date of Birth",
          type: "date", // Could be "date" if the system supports date formats
          snap_id: (index) => {
            return `Date of Birth${index + 1}`;
          },
        },
        {
          id: "social_security_number",
          title: "Social Security Number (SSN)",
          type: "text",
          snap_id: (index) => {
            return `Social Security Number${index + 1}`;
          },
        },
        {
          id: "relationship_to_applicant",
          title: "Relationship to you?",
          type: "text",
          suggestions: allRelations,
          snap_id: (index) => {
            return `Relationship to you${index + 1}`;
          },
        },
        {
          id: "us_citizen",
          title: "Is this person a US citizen?",
          type: "boolean",
          value: false,
          snap_id: (index) => {
            return `Is this person  U.S. Citizen${index + 1}?`;
          },
        },
        {
          id: "applying_for_snap",
          title: "Is this person applying for SNAP benefits?",
          type: "boolean",
          value: false,
          snap_id: (index) => {
            return `Is this person applying for SNAP benefits${index + 1}?`;
          },
        },
      ],
    },
  },
  {
    id: "worked_recently",
    title: "Has anyone worked in the last 60 days?",
    snap_id: "Has anyone worked in the last 60 days?",
    render_if: (form) => {
      return form["live_with_others"];
    }, // render if true
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 4,
      questions: [
        {
          id: "person_with_income",
          title: "Person with Income",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: (index) => {
            return `Person with Income${index + 1}_pg4`;
          },
        },
        {
          id: "income_type",
          title: "Income Type",
          type: "text",
          suggestions: ["Wages", "Self-Employment"],
          snap_id: (index) => {
            return [
              `Other income type${index + 1}_pg4`,
              `Other income type${index + 1}`,
            ];
          },
        },
        {
          id: "employer",
          title: "Employer",
          type: "text",
          snap_id: (index) => {
            return `Employer${index + 1}`;
          },
        },
        {
          id: "income_frequency",
          title: "Frequency",
          suggestions: ["Weekly", "Biweekly"],

          type: "text", // This could specify the interval, e.g., weekly, bi-weekly, monthly
          snap_id: (index) => {
            return [
              `Other payment frequency${index !== 0 ? index + 1 : ""}_pg4`,
              `Other payment frequency${index + 1}`,
            ];
          },
        },
        {
          id: "gross_earnings_last_4_weeks",
          title: "Gross Earnings for Last 4 Weeks",
          type: "text", // Could be "number" if you prefer to capture numeric values
          snap_id: (index) => {
            return `First check_${index + 1}`;
          },
        },
      ],
    },
  },
  {
    id: "other_income",
    render_if: (form) => {
      return form["live_with_others"];
    },
    snap_id:
      "Does anyone receive any other type of income such as Unemployment Compensation, Child Support, Social Security, SSI, Workers’ Compensation, Veterans’ Benefits, Pensions or Rental Income?",
    title:
      "Does anyone receive any other type of income such as Unemployment Compensation, Child Support, Social Security, SSI, Workers’ Compensation, Veterans’ Benefits, Pensions, or Rental Income?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 4,
      questions: [
        {
          id: "person_with_income",
          title: "Person with Income",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: (index) => {
            return `Person with Other Type of Income Row${index + 1}_pg4`;
          },
        },
        {
          id: "income_type",
          title: "Income Type",
          type: "text",
          suggestions: ["Wages", "Self-Employment"],
          snap_id: (index) => {
            return `Other Income type Row${index + 1}_pg4`;
          },
        },
        {
          id: "income_frequency",
          title: "Frequency",
          suggestions: ["Weekly", "Biweekly", "Monthly"],
          type: "text", // This can be adapted to specify the interval e.g., weekly, bi-weekly, monthly
          snap_id: (index) => {
            return [
              `Other Row${index + 1}_pg4`,
              `Other payment frequency Row${index + 1}_pg4?`,
            ];
          },
        },
        {
          id: "gross_amount",
          title: "Gross Amount",
          type: "text", // Could be "number" if capturing numeric values is desired
          snap_id: (index) => {
            return `Gross amount Row${index + 1}_pg4`;
          },
        },
      ],
    },
  },
  {
    id: "pay_shelter_costs",
    render_if: (form) => {
      return form["live_with_others"];
    },
    title: "Is the household responsible to pay shelter costs?",
    snap_id: "Shelter costs",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 3,
      questions: [
        {
          id: "shelter_cost_details",
          title: "Provide details of your shelter costs:",
          type: "select",
          options: [
            "Rent",
            "Mortgage",
            "Property Taxes",
            "Home Insurance",
            "Condo fee",
            "Other",
          ],
          snap_id: (index, value) => {
            return value + "_pg5";
          },
        },
        {
          id: "amount",
          title: "Amount",
          type: "text",
          placeholder: "$ _________",
          snap_id: (index, value, entries) => {
            console.log("==>row ", entries);
            const name = entries[0].value;
            const mappings = {
              Rent: "Rent",
              Mortgage: "Mortgage",
              "Property Taxes": "Property tax",
              "Home Insurance": "Home insurance",
              "Condo fee": "Condo fee",
              Other: "Other type of shelter cost",
            };
            return `${mappings[name]} amount`;
          },
        },
        {
          id: "frequency",
          title: "Frequency",
          type: "text",
          suggestions: ["Monthly", "Weekly", "Quarterly", "Annually", "Other"],
          snap_id: (index, value, entries) => {
            const name = entries[0].value;
            const mappings = {
              Rent: "rent",
              Mortgage: "mortgage",
              "Property Taxes": "property tax",
              "Home Insurance": "home insurance",
              "Condo fee": "condo fee",
              Other: "shelter cost",
            };
            return [
              `Other ${mappings[name]} payment frequency`,
              `${name}_frequency_other_pg5`,
            ];
          },
        },
      ],
    },
  },
  {
    id: "pay_utility_costs",
    title:
      "Is the household responsible to pay utility costs separate from shelter costs?",
    type: "boolean",
    value: false,
    snap_id: "Utility costs",
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "utility_service_options",
          title:
            "Select all utility and service options applicable to your household:",
          type: "multi-select",
          options: [
            "Heat (oil, gas, electricity or propane, etc.)",
            "Electricity and/or gas (other than heating use); water; sewage; and/or trash collection",
            "Electricity for an air conditioner in the summer",
            "A fee to use an air conditioner in the summer",
            "Phone or cell phone service (including pre-paid)",
          ],
          snap_id: (index, value) => {
            const ids = [];
            const mapping = {
              "Heat (oil, gas, electricity or propane, etc.)":
                "Heat oil gas electricity or propane etc",
              "Electricity and/or gas (other than heating use); water; sewage; and/or trash collection":
                "Electricity andor gas other than heating use",
              "Electricity for an air conditioner in the summer":
                "Electricity for an air conditioner in the summer",
              "A fee to use an air conditioner in the summer":
                "A fee to use an air conditioner in the summer",
              "Phone or cell phone service (including pre-paid)":
                "Phone or cell phone service including prepaid",
            };
            if (Array.isArray(value)) {
              value.forEach((e) => {
                ids.push(mapping[e]);
              });
            }
            return ids;
          },
        },
      ],
    },
  },
  {
    id: "adult_child_care_costs",
    render_if: (form) => {
      return form["live_with_others"];
    },
    title: "Is anyone responsible to pay for adult or child care costs?",
    snap_id: "Dependent Care Costs",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 4,
      questions: [
        {
          id: "name_of_child_or_dependent",
          title: "Name of Child or Dependent",
          type: "text",
          snap_id: (index) => {
            return `Row${index + 1}_name of dependent_pg6`;
          },
        },
        {
          id: "amount_paid",
          title: "Amount Paid",
          type: "text", // Use "number" if you prefer numeric input
          snap_id: (index) => {
            return `Row${index + 1}_amount_pg6`;
          },
        },
        {
          id: "payment_frequency",
          title: "Frequency",
          type: "text",
          snap_id: (index) => {
            return [
              `Row${index + 1}_frequency_other_pg6`,
              `Other dependent cost frequency${index + 1}`,
            ];
          },
        },
      ],
    },
  },
  {
    id: "travel_dependent_care",
    render_if: (form) => {
      return form["live_with_others"];
    },
    title: "Does anyone travel to and/or from a dependent care provider?",
    type: "boolean",
    value: false,
    snap_id: "Travel costs to and/or from dependent care provider",
    more: {
      when: true,
      limit: 4,
      questions: [
        {
          id: "name_of_medical_provider",
          title: "Name of Medical Provider",
          type: "text",
          snap_id: (index) => {
            return `Row${index + 1 + 4}_name of dependent_pg6`;
          },
        },
        {
          id: "address_of_medical_provider",
          title: "Address of Medical Provider",
          type: "text",
          snap_id: (index) => {
            return `Row${index + 1 + 4}_address of care provider_pg6`;
          },
        },
        {
          id: "number_of_car_trips_to",
          title: "Number of Car Trips per Week (TO the medical provider)",
          type: "text",
          snap_id: (index) => {
            return `Row${
              index + 1 + 4
            }_From your home to Care Provider for dependent${index + 1}_pg6`;
          },
        },
        {
          id: "number_of_car_trips_from",
          title: "Number of Car Trips per Week (FROM the medical provider)",
          type: "text",
          snap_id: (index) => {
            return `Row${
              index + 1 + 4
            }_From Care Provider to your home for dependent${index + 1}_pg6`;
          },
        },
        {
          id: "cost_for_parking_public_transportation",
          title:
            "Cost for Parking, Public Transportation, Taxi Cab, Shuttle, etc.",
          type: "text",
          snap_id: (index) => {
            return `If not driving: cost of public transportation for dependent${
              index + 1
            }_pg6`;
          },
        },
      ],
    },
  },
  {
    id: "medical_expenses_elderly_disabled",
    render_if: (form) => {
      return form["live_with_others"];
    },
    snap_id: "Medical Costs",
    title:
      "Does anyone who is at least 60 years old and/or disabled have out-of-pocket medical expenses?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 3,
      questions: [
        {
          id: "name",
          title: "Name",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: (index) => {
            return `Name${index + 1}`;
          },
        },
        {
          id: "expense_type",
          title: "Expense Type (Select all that apply)",
          type: "multi-select",
          options: [
            "Medical Care",
            "Dental Care",
            "Medications",
            "Health Insurance",
            "Other (over-the-counter medications, medical supplies, etc.)",
          ],
          snap_id: (index, values) => {
            const ids = [];
            const mappings = {
              "Medical Care": "Medical Care",
              "Dental Care": "Dental Care",
              Medications: "Medications",
              "Health Insurance": "Health Insurance",
              "Other (over-the-counter medications, medical supplies, etc.)":
                "Other",
            };
            if (!Array.isArray(values)) return -1;
            values.forEach((value) => {
              ids.push(mappings[value] + Number(index + 1));
            });
            return ids;
          },
        },
        {
          id: "total_cost_per_month",
          title: "Total Cost Per Month",
          type: "text", // "number" if aiming for numerical values
          snap_id: (index) => {
            return `Total cost per month${index + 1}`;
          },
        },
      ],
    },
  },
  {
    id: "travel_medical_care",
    render_if: (form) => {
      return form["live_with_others"];
    },
    snap_id:
      "Does anyone travel to and/or from medical care such as a pharmacy, doctor, therapist, etc.)?",
    title:
      "Does anyone travel to and/or from medical care (such as a pharmacy, doctor, therapist, etc.)?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 3,
      questions: [
        {
          id: "name_of_medical_provider",
          title: "Name of Medical Provider",
          type: "text",
          snap_id: (index) => {
            return `Name${index + 1}_pg7`;
          },
        },
        {
          id: "address_of_medical_provider",
          title: "Address of Medical Provider",
          type: "text",
          snap_id: (index) => {
            return `Address of Medical Provider${index + 1}`;
          },
        },
        {
          id: "number_of_car_trips_to",
          title: "Number of Car Trips per Week (TO the medical provider)",
          type: "text", // Use "number" if you prefer numeric input
          snap_id: (index) => {
            return `To${index + 1}`;
          },
        },
        {
          id: "number_of_car_trips_from",
          title: "Number of Car Trips per Week (FROM the medical provider)",
          type: "text", // Use "number" if you prefer numeric input
          snap_id: (index) => {
            return `From${index + 1}`;
          },
        },
        {
          id: "cost_for_parking_public_transportation",
          title:
            "Cost for Parking, Public Transportation, Taxi Cab, Shuttle, etc.",
          type: "text", // Use "number" if you prefer numeric input
          snap_id: (index) => {
            return `Cost for Parking Public Transportation Taxi Cab Shuttle etc${
              index + 1
            }`;
          },
        },
      ],
    },
  },
  {
    id: "training_program",
    render_if: (form) => {
      return form["live_with_others"];
    },
    title: "Is anyone in a training program at least 80 hours per month?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 4,
      questions: [
        {
          id: "name_of_person_or_agency",
          title: "Who:",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: "If yes, who?1",
        },
      ],
    },
  },
  {
    id: "in_kind_work",
    render_if: (form) => {
      return form["live_with_others"];
    },
    title:
      "Is anyone working in exchange for goods and services (in-kind work)?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          title: "Who:",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: "If yes, who?3",
        },
      ],
    },
  },
  {
    id: "unpaid_internship",
    render_if: (form) => {
      return form["live_with_others"];
    },
    title: "Is anyone doing an unpaid internship?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          title: "Who:",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: "If yes, who?5",
        },
      ],
    },
  },
  {
    id: "volunteering",
    render_if: (form) => {
      return form["live_with_others"];
    },
    title: "Is anyone volunteering?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          title: "Who:",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id: "If yes, who?7",
        },
      ],
    },
  },
  {
    id: "snap_benefits_outside_massachusetts",
    render_if: (form) => {
      return form["live_with_others"];
    },
    snap_id:
      "Has anyone received SNAP benefits outside of MA within past 30 days?",
    title:
      "Has anyone received SNAP benefits outside Massachusetts within the past 30 days?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          title: "Who:",
          s_path: ["live_with_others", 0],
          type: "text",
          snap_id:
            "Has anyone recieved SNAP benefits outside Massachusetts within the past 30 days? If yes, who?",
        },
      ],
    },
  },
  {
    id: "permission_to_contact",
    snap_id:
      "Do you want to give us permission to contact a person or agency if we cannot reach you by phone?*",
    title:
      "Do you want to give us permission to contact a person or agency if we cannot reach you by phone?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          title: "Name of Person or Agency:",
          type: "text",
          snap_id: "Assisting with Applicatioin - Name of Person or Agency",
        },
        {
          id: "phone_number",
          title: "Phone Number:",
          type: "text",
          snap_id: "Assisting with Application - Phone Number",
        },
        {
          id: "address",
          title: "Address:",
          type: "text",
          snap_id: "Assisting with Application - Address",
        },
      ],
    },
  },
  {
    id: "permission_to_speak_dta",
    snap_id:
      "Do you want to give a person or agency permission to speak with DTA and get relevant confidential information about your case?*",
    title:
      "Do you want to give a person or agency permission to speak with DTA and get relevant confidential information about your case?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          title: "Name of Person or Agency:",
          type: "text",
          snap_id:
            "Authorization to Release Information - Name of Person or Agency",
        },
        {
          id: "phone_number",
          title: "Phone Number:",
          type: "text",
          snap_id: "Authorizaton to Release Information - Phone Number",
        },
        {
          id: "address",
          title: "Address:",
          type: "text",
          snap_id: "Authorization to Release Information - Address",
        },
      ],
    },
  },
  {
    id: "permission_to_act_on_behalf",
    snap_id:
      "Do you want to give an agency or someone you trust permission to sign forms, report changes, complete interviews, and talk about your case with us?*",
    title:
      "Do you want to give an agency or someone you trust permission to sign forms, report changes, complete interviews, and talk about your case with us?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person_or_agency",
          title: "Name of Person or Agency:",
          type: "text",
          snap_id:
            "Authorized Representative for Certification - Name of Person or Agency",
        },
        {
          id: "phone_number",
          title: "Phone Number:",
          type: "text", // Could specify format if needed
          snap_id: "Authorized Representative for Certification - Phone Number",
        },
        {
          id: "federal_employer_identification_number",
          title: "Federal Employer Identification Number (Agency Only):",
          type: "text", // This field is optional and specific to agencies
          snap_id: "Federal Employer ID Number - Agency Only",
        },
      ],
    },
  },
  {
    id: "permission_for_ebt_shopping",
    snap_id:
      "Do you want to give someone you trust permission to get an EBT card to food shop for you using your SNAP benefits?*",
    title:
      "Do you want to give someone you trust permission to get an EBT card to food shop for you using your SNAP benefits?",
    type: "boolean",
    value: false,
    more: {
      when: true,
      limit: 1,
      questions: [
        {
          id: "name_of_person",
          title: "Name of Person:",
          type: "text",
          snap_id: "Authorized Rep for EBT Transactions - Name of Person",
        },
        {
          id: "phone_number",
          title: "Phone Number:",
          type: "text", // Could specify format if needed
          snap_id: "Authorized Rep for EBT Transactions - Phone Number",
        },
        {
          id: "address",
          title: "Address:",
          type: "text",
          snap_id: "Authorized Rep for EBT Transactions - Address",
        },
      ],
    },
  },

  fields.consent(formName),
];
