import React, { useState, useCallback, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Radio,
  Checkbox,
  Button,
  Typography,
  Steps,
  message,
  DatePicker,
  Divider,
  Tag,
  AutoComplete,
} from "antd";
import debounce from "lodash/debounce";
import LocationAutocomplete from "./components/goog/LocationAutoComplete";
import { catcher } from "../../components/trycatch/catcher";
import "./form.styles.css";
import dayjs from "dayjs";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons"; // Using Ant Design icons for visual improvement
import { updateUser } from "../../backend/users/set";
import useSize from "../../components/use/useSize";

const { Option } = Select;
const { Step } = Steps;

const FieldRenderer = ({ field, form, displayOnly }) => {
  const { isMobile } = useSize();
  let {
    title,
    id,
    required,
    type,
    options,
    info,
    validate,
    date_format = "MM/DD/YYYY",
    ins,
    more,
  } = field;

  // Handle options formatting
  if (
    Array.isArray(options) &&
    options.length > 0 &&
    typeof options[0] !== "object"
  ) {
    options = options.map((option) => ({ label: option, value: option }));
  }

  // State to manage clusters of additional questions
  const [additionalClusters, setAdditionalClusters] = useState(
    more?.questions
      ? [
          more.questions.map((q, i) => ({
            ...q,
            id: `${id}_more_0_${i}_${q.id}`,
          })),
        ]
      : []
  );
  const [clusterCount, setClusterCount] = useState(1); // Start with one cluster
  const clusterLimit = more?.limit || 4; // Default limit to 4 clusters if not provided

  const getValidationRules = () => {
    let message = "This field is required";
    try {
      message = `Please add ${title?.toLowerCase()}`;
    } catch (err) {}
    const rules = [{ required, message }];
    if (validate) {
      rules.push({
        validator: async (_, value) => {
          try {
            await validate(value);
          } catch (error) {
            throw new Error(error.message);
          }
        },
      });
    }
    return rules;
  };

  const formItemProps = {
    key: id,
    label: title,
    name: id,
    size: "large",
    rules: getValidationRules(),
    style: { pointerEvents: displayOnly ? "none" : "initial" },
  };
  const itemProps = {
    border: displayOnly ? "none !important" : "initial",
  };

  // Handle adding a new cluster of questions
  const handleAddCluster = () => {
    if (clusterCount < clusterLimit) {
      const newCluster = more.questions.map((q, i) => ({
        ...q,
        id: `${id}_more_${clusterCount}_${i}_${q.id}`,
      }));
      setAdditionalClusters([...additionalClusters, newCluster]);
      setClusterCount(clusterCount + 1);
    }
  };

  // Handle removing the last cluster of questions
  const handleRemoveLastCluster = () => {
    if (clusterCount > 1) {
      const updatedClusters = additionalClusters.slice(0, -1);
      setAdditionalClusters(updatedClusters);
      setClusterCount(clusterCount - 1);
    }
  };

  switch (type) {
    case "heading":
      return (
        <Typography.Title level={3} key={id} translate="no">
          {title}
        </Typography.Title>
      );

    case "text":
      return (
        <Form.Item {...formItemProps}>
          {field.suggestions ? (
            <AutoComplete
              options={field.suggestions.map((s) => ({ value: s }))}
              placeholder={info || title}
              {...itemProps}
            />
          ) : (
            <Input placeholder={info || title} {...itemProps} />
          )}
        </Form.Item>
      );

    case "textarea":
      return (
        <Form.Item {...formItemProps}>
          <Input.TextArea placeholder={info || title} {...itemProps} />
        </Form.Item>
      );

    case "select":
      return (
        <Form.Item {...formItemProps}>
          <Select
            {...itemProps}
            placeholder="Select an option"
            showSearch
            filterOption={(input, option) =>
              option?.children?.toLowerCase().includes(input?.toLowerCase())
            }
          >
            {options?.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.text}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );

    case "radio":
      return (
        <>
          {ins}
          <Form.Item {...formItemProps}>
            <Radio.Group
              size={isMobile ? "middle" : formItemProps.size}
              optionType="button"
              {...itemProps}
            >
              {options?.map((option) => (
                <Radio.Button key={option.value} value={option.value}>
                  {option.text}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </>
      );

    case "checkbox":
      if (options?.length > 0) {
        return (
          <Form.Item {...formItemProps}>
            <Checkbox.Group
              style={{ display: "flex", flexDirection: "column" }}
              {...itemProps}
            >
              {options?.map((option) => (
                <Checkbox key={option.value} value={option.value}>
                  {option.text}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        );
      } else {
        return (
          <Form.Item {...formItemProps} valuePropName="checked">
            <Checkbox {...itemProps}>{title}</Checkbox>
          </Form.Item>
        );
      }

    case "date":
      return (
        <Form.Item
          {...formItemProps}
          getValueProps={(value) => ({
            value: value ? dayjs(value, date_format) : null,
          })}
          getValueFromEvent={(value) =>
            value ? value.format(date_format) : null
          }
        >
          <DatePicker
            style={{ width: "100%" }}
            {...itemProps}
            placeholder={info || title}
            format={date_format}
          />
        </Form.Item>
      );

    case "boolean":
      return (
        <>
          {/* Main Boolean Field */}
          <Form.Item {...formItemProps}>
            <Radio.Group
              {...itemProps}
              onChange={(e) => form.setFieldsValue({ [id]: e.target.value })}
            >
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>

          {/* Render clusters of questions */}
          {more && form.getFieldValue(id) === more.when && (
            <div style={{ marginLeft: "20px", marginTop: "10px" }}>
              {additionalClusters.map((cluster, clusterIndex) => (
                <div
                  key={`cluster_${clusterIndex}`}
                  style={{
                    marginBottom: "15px",
                    padding: "10px",
                    border: "1px solid #e0e0e0",
                    borderRadius: "0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <Typography.Title
                      level={4}
                      style={{ lineHeight: "0px", marginBottom: "10px" }}
                    >
                      Entry {clusterIndex + 1}
                    </Typography.Title>
                    {clusterCount > 1 && (
                      <MinusCircleOutlined
                        style={{
                          fontSize: "18px",
                          color: "#ff4d4f",
                          cursor: "pointer",
                        }}
                        onClick={handleRemoveLastCluster}
                      />
                    )}
                  </div>

                  {cluster.map((question) => (
                    <FieldRenderer
                      key={question.id}
                      field={question}
                      form={form}
                      displayOnly={displayOnly}
                    />
                  ))}
                </div>
              ))}

              {/* Full-width Add More button */}
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                onClick={handleAddCluster}
                disabled={clusterCount >= clusterLimit || displayOnly}
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              >
                Add Entry
              </Button>
            </div>
          )}
        </>
      );

    default:
      return null;
  }
};

const FormComponent = ({
  id,
  uuid,
  formData,
  displayOnly = false,
  initialValues = {},
  onSubmit,
  displayThanksOnSubmit = false,
  displaySteps = true,
}) => {
  const [form] = Form.useForm();
  const [removeFilled, setRemoveFilled] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(() => {
    const savedValues = localStorage.getItem("form " + id);
    console.log("Saved values", savedValues, "initial values", initialValues);
    if (savedValues) {
      return { ...JSON.parse(savedValues) };
    }
    return initialValues;
  });

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (initialValues && initialValues[`${id}-started_on`]) {
    } else updateUser(uuid, { [`${id}-started_on`]: Date.now() });
  }, []);

  const debouncedUpdateFormValues = useCallback(
    debounce((values) => {
      setFormValues((prevValues) => {
        const newValues = { ...prevValues, ...values };
        localStorage.setItem("form " + id, JSON.stringify(newValues));
        console.log("Form saved", newValues);
        return newValues;
      });
    }, 300),
    []
  );

  const debouncedUpdateUser = useCallback(
    debounce((values) => {
      if (uuid) {
        updateUser(
          `raw-${uuid}-${id}`,
          { ...values, [`${id}-last_saved_on`]: Date.now() },
          "Raw"
        );
        console.log("User synced", values);
      } else {
        console.log("Uuid not found");
      }
    }, 300),
    []
  );

  useEffect(() => {
    form.setFieldsValue(formValues);
  }, [formValues, form]);

  useEffect(() => {
    if (!displayOnly)
      formData.forEach((field, index) => {
        if (initialValues[field.id] !== undefined) {
          return setRemoveFilled(true);
        }
      });
  }, [formData]);
  const handleFormChange = (_, values) => {
    debouncedUpdateFormValues(values);
    debouncedUpdateUser(values);
  };

  const handleNext = async () => {
    try {
      const currentFields = await form.validateFields();
      setFormValues((prevValues) => ({ ...prevValues, ...currentFields }));
      setCurrentStep(currentStep + 1);
    } catch (error) {
      message.error("Please fill out the required fields.");
    }
  };

  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFinish = async () => {
    await catcher(
      async () => {
        const currentFields = await form.validateFields();
        const allValues = { ...formValues, ...currentFields };
        await onSubmit(allValues);
        if (displayThanksOnSubmit) {
          message.success("Form submitted successfully!");
        }
      },
      { setLoading, err_msg: "Please fill out the required fields correctly." }
    );
  };

  const renderFormFields = () => {
    let steps = [];
    let currentPanel = [];
    let currentHeading = null;
    let panelIndex = 0;

    formData.forEach((field, index) => {
      if (initialValues[field.id] !== undefined) {
        if (removeFilled === true) return;
      }
      if (typeof field.render_if === "function") {
        if (!field.render_if(formValues)) {
          return;
        }
      }
      if (!displaySteps && index > 0 && field.type === "heading") return;
      if (field.type === "heading") {
        if (currentHeading) {
          steps.push({
            title: currentHeading.title,
            content: currentPanel,
            key: panelIndex,
          });
          currentPanel = [];
          panelIndex++;
        }
        currentHeading = field;
      } else {
        currentPanel.push(
          <FieldRenderer
            key={field.id}
            field={field}
            form={form}
            displayOnly={displayOnly}
          />
        );
      }
    });

    if (currentHeading) {
      steps.push({
        title: currentHeading.title,
        content: currentPanel,
        key: panelIndex,
      });
    }

    return steps;
  };

  const steps = renderFormFields();

  return (
    <div className="form-container">
      <Steps
        current={currentStep}
        className={steps.length === 1 ? "hidden" : ""}
      >
        {steps.map((step) => (
          <Step key={step.key} title={step.title} />
        ))}
      </Steps>
      <div id="other-settings">
        {removeFilled !== undefined ? (
          <div className="remove-filled">
            <Checkbox
              style={{ marginTop: "15px", marginLeft: "5px" }}
              checked={removeFilled}
              onChange={(e) => {
                setRemoveFilled(e.target.checked);
              }}
            >
              Remove filled fields
            </Checkbox>
          </div>
        ) : null}
      </div>
      <Form
        form={form}
        size="large"
        layout="vertical"
        onValuesChange={handleFormChange}
        initialValues={formValues}
        requiredMark={false}
        className="form"
      >
        <div>{steps[currentStep]?.content}</div>
        <Form.Item>
          <div style={{ textAlign: "right", marginTop: "10px" }}>
            {currentStep > 0 && (
              <Button onClick={handlePrevious} style={{ marginRight: "10px" }}>
                Previous
              </Button>
            )}
            {currentStep < steps.length - 1 ? (
              <Button onClick={handleNext} type="primary">
                Next
              </Button>
            ) : (
              <>
                {displayOnly ? null : (
                  // <Button>Ok</Button>
                  <Button
                    type="primary"
                    onClick={handleFinish}
                    loading={loading}
                  >
                    Submit
                  </Button>
                )}
              </>
            )}
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormComponent;
