import React, { useState, useEffect, useRef } from "react";
import {
  Widget,
  addResponseMessage,
  toggleMsgLoader,
  deleteMessages,
  isWidgetOpened,
  addLinkSnippet,
  addUserMessage,
  markAllAsRead,
  setQuickButtons,
  toggleInputDisabled,
  toggleWidget,
  dropMessages,
} from "react-chat-widget";
import axios from "axios";
import "react-chat-widget/lib/styles.css";
import "./Chatbot.css";
import { config } from "./Chatbot.config";
import { FaTrash } from "react-icons/fa6";
import { createUser, updateUser } from "./backend/users/set";
import { getLsId, getRandomString } from "./helpers/random";
import { getUserById } from "./backend/users/get";

import sendButtonColored from "../src/assets/images/send-button-colored.png";
import sendButtonUnColored from "../src/assets/images/send-button-uncolored.png";
import { createPluckyCase, createPluckyEvent } from "./captureInPlucky";
import Gtranslate, { LanguageSelectionButton } from "./Gtranslate";
import { clearLocalStorageExcept } from "./helpers/loc";

const { REACT_APP_CHAT_BACKEND_BASE_URL } = process.env;

console.log("CHAT_BACKEND_BASE_URL: ", REACT_APP_CHAT_BACKEND_BASE_URL);

const linkDictionary = {
  snap: "/form/snap",
  wic: "/form/wic",
  lifeline: "/form/lifeline",
};

let bool_new_msg = true;

export const fetchAIResponse = async (
  message,
  botThreadID = JSON.parse(localStorage.getItem("threadID") || "null"),
  botConfig = config,
  setThreadID = () => {},
  set_possible_responses = true
) => {
  let headers = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  let data = JSON.stringify({
    threadID: botThreadID,
    message: message,
    assistantID: botConfig.assistantID,
  });

  let reqData = {
    url: `${REACT_APP_CHAT_BACKEND_BASE_URL}chat`,
    method: "POST",
    headers,
    data: data,
  };

  let response = await axios.request(reqData);
  try {
    console.log("____RESPONSE____", response?.data, { set_possible_responses });
    console.log(JSON.parse(response.data.history));
  } catch (err) {}

  if ("threadID" in response.data && response?.data?.threadID) {
    setThreadID(response.data.threadID);
    botThreadID = response.data.threadID;
    localStorage.setItem("threadID", JSON.stringify(response.data.threadID));
  }
  let botResponse = "";
  try {
    botResponse = JSON.parse(response.data.resp_text);
  } catch {
    botResponse = response.data.resp_text;
  }
  createPluckyEvent("user message", message, botThreadID);
  createPluckyEvent("bot response", botResponse, botThreadID);
  return botResponse;
};

function formatLinkWithMultipleDomains(url) {
  try {
    // Create a URL object
    const urlObj = new URL(url);

    // Iterate over each path-domain pair in the array
    for (const mapping of config.linkParser) {
      const path = Object.keys(mapping)[0]; // Get the path
      const newDomain = mapping[path]; // Get the corresponding domain

      // Check if the current path matches the URL's path
      if (urlObj.pathname === path) {
        // Replace the domain and return the modified URL
        return `${newDomain}${urlObj.pathname}`;
      }
    }

    // If no match is found, return the original URL
    return url;
  } catch (error) {
    // If the input is not a valid URL, return it as is
    console.error("Invalid URL provided:", url);
    return url;
  }
}

let addBotMessageFunction = null;

export const addBotMessage = (message, openBot = false) => {
  if (addBotMessageFunction) {
    addBotMessageFunction(message);
    if (openBot) {
      toggleWidget();
    }
  } else {
    console.error("ChatBotComponent is not yet initialized");
  }
};

// helper function to parse links.

function processString(inputString) {
  // Dictionary of replacements

  function replaceLinksInText(text, linkDictionary) {
    const urlRegex = /(https?:\/\/[^\s\)]+)/g; // Match URLs, including those with parentheses

    // Replace the matched links with the dictionary values if a match is found
    const updatedText = text.replace(urlRegex, (match) => {
      // Check each key in the dictionary
      for (const key in linkDictionary) {
        // Check if the key is found within the URL
        if (match.toLowerCase().includes(key.toLowerCase())) {
          return linkDictionary[key]; // Replace the link with the dictionary value
        }
      }
      return match; // If no match, return the original link
    });

    return updatedText;
  }

  return replaceLinksInText(inputString, linkDictionary);
}

function parseResponse(input, makeButtons = true) {
  console.log("response", input);
  input = processString(input);

  // Remove "Assistant:" if present and trim spaces
  input = input.replace("Assistant:", "").trim();

  // Remove starting and ending quotes if they exist
  if (
    (input.startsWith('"') && input.endsWith('"')) ||
    (input.startsWith("'") && input.endsWith("'"))
  ) {
    input = input.slice(1, -1);
  }

  // Final trim to remove any extra spaces
  input = input.trim();
  if (!makeButtons) return input;
  console.log("parsed response", input);
  const responsePattern =
    /#\s*BASIC_RESPONSE_RSWVKCLAMSKN([\s\S]*?)(?=#\s*BASIC_BUTTON_RSWVKCLAMSKN|#\s*BASIC_LINK_RSWVKCLAMSKN|$)/;
  const buttonPattern = /#\s*BASIC_BUTTON_RSWVKCLAMSKN([\s\S]*?)(?=$)/;
  const linkPattern =
    /#\s*BASIC_LINK_RSWVKCLAMSKN([\s\S]*?)(?=#\s*BASIC_BUTTON_RSWVKCLAMSKN|$)/;

  const responseMatch = input.match(responsePattern);
  const buttonMatch = input.match(buttonPattern);
  const linkMatch = input.match(linkPattern);

  // Default to the entire input as the response if no tags are found
  const response = responseMatch
    ? responseMatch[1].trim()
    : input
        .replace(
          /#\s*BASIC_BUTTON_RSWVKCLAMSKN[\s\S]*|#\s*BASIC_LINK_RSWVKCLAMSKN[\s\S]*/g,
          ""
        )
        .trim();

  const actionButtons = buttonMatch
    ? buttonMatch[1]
        .trim()
        .split("\n")
        .filter(Boolean)
        .map((label) => {
          const cleanLabel = label.replace(/^\d+\.\s*/, ""); // Remove numbering like "1. " from the button labels
          return { label: cleanLabel, value: cleanLabel };
        })
    : [];

  let link;
  try {
    link = linkMatch
      ? linkMatch[1]
          .trim()
          .split("\n")
          .filter(Boolean)
          .reduce((acc, curr, index, array) => {
            if (index % 2 === 0) {
              const title = curr.replace(/^\d+\.\s*/, "").trim(); // Remove numbering and trim
              const linkUrl = array[index + 1]?.trim(); // Get the next item as the link
              if (title && linkUrl) {
                acc.push({ title, link: linkUrl, target: "_self" });
              }
            }
            return acc;
          }, [])
      : [];
  } catch {
    link = undefined;
  }

  // Build the result object
  const data = {
    response,
    actionButtons: actionButtons.length > 0 ? actionButtons : undefined,
    link: link?.length > 0 ? link : undefined,
  };

  // Remove undefined keys
  Object.keys(data).forEach(
    (key) => data[key] === undefined && delete data[key]
  );

  console.log(data);
  return data;
}

export const ChatBotComponent = () => {
  const [messages, setMessages] = useState([]);
  const [botThreadID, setThreadID] = useState(null);

  useEffect(() => {
    // Set the global function reference when the component mounts
    addBotMessageFunction = handleAddResponseMessage;

    // Existing useEffect code...
    handleToggle();
    if (!window.location.href.includes("/form/")) toggleWidget();
    console.log(isWidgetOpened());
    const l_thread = localStorage.getItem("threadID");
    if (l_thread) {
      setThreadID(JSON.parse(l_thread));
    }

    return () => {
      // Clean up the global reference when the component unmounts
      addBotMessageFunction = null;
    };
  }, []);

  useEffect(() => {
    // Check and update all links inside the widget to set target="_self" and format the URL
    const updateLinksTarget = () => {
      const chatWidget = document.querySelector(".rcw-conversation-container");
      if (chatWidget) {
        const links = chatWidget.querySelectorAll("a");
        links.forEach((link) => {
          // Set target to _self
          link.setAttribute("target", "_self");

          // Format the URL
          const originalUrl = link.getAttribute("href");
          const formattedUrl = formatLinkWithMultipleDomains(originalUrl);
          link.setAttribute("href", formattedUrl);
        });
      }
    };

    // Run on initial mount
    updateLinksTarget();

    // Run again whenever messages state changes
    const observer = new MutationObserver(updateLinksTarget);
    const chatWidget = document.querySelector(".rcw-conversation-container");
    if (chatWidget) {
      observer.observe(chatWidget, { childList: true, subtree: true });
    }

    // Cleanup
    return () => {
      if (chatWidget) {
        observer.disconnect();
      }
    };
  }, [messages]);

  function updateUI() {
    function deleteDuplicateNeighborMessages() {
      const messages = document.querySelectorAll(".rcw-message");

      messages.forEach((message, index) => {
        if (index > 2 && index < messages.length - 1) {
          // Ensure there’s a next message
          const nextMessage = messages[index + 1];

          const currentContent = message.innerHTML.trim();
          const nextContent = nextMessage.innerHTML.trim();

          // If content matches, remove the duplicate message
          if (currentContent === nextContent) {
            nextMessage.remove(); // Directly remove the duplicate
            console.log("Removing duplicate message:", nextMessage);
          }
        }
      });
    }

    // deleteDuplicateNeighborMessages();

    const optionsContainer = document.querySelector(".rcw-picker-btn");
    const inputField = document.querySelector(".rcw-input");
    const sendIcon = document.querySelector(".rcw-send-icon");

    if (inputField && sendIcon) {
      sendIcon.src = inputField.textContent.trim()
        ? sendButtonColored
        : sendButtonUnColored;
    }

    // Exit early if optionsContainer is missing or delete button already exists
    if (!optionsContainer || optionsContainer.querySelector(".delete-btnx"))
      return;

    // Clear container and create delete button
    optionsContainer.innerHTML = "";

    const deleteBtn = document.createElement("img");
    deleteBtn.classList.add("rcw-picker-icon", "delete-btnx");
    deleteBtn.src = "https://cdn-icons-png.flaticon.com/512/61/61225.png";
    deleteBtn.style.width = "20px";

    deleteBtn.addEventListener("click", (e) => {
      if (window.confirm("Delete session and restart chat?")) {
        e.preventDefault();
        clearLocalStorageExcept();
        setThreadID(null);
        dropMessages();
        setTimeout(() => {
          handleAddResponseMessage(config.newMessage);
        }, 200);
      }
    });

    // Append delete button to container
    optionsContainer.appendChild(deleteBtn);
  }

  useEffect(() => {
    async function init() {
      try {
        if (botThreadID) {
          createPluckyCase("Chat:" + botThreadID, "chat", botThreadID);
          let uuid = getLsId();
          let user = await getUserById(uuid);

          // Check if user retrieval was successful
          if (!user) {
            uuid = await createUser({}, uuid);
            user = await getUserById(uuid);

            // Check if user creation and retrieval were successful
            if (!user) throw new Error("User creation failed.");
          }

          let chat_ids = user.chat_ids ?? [];

          // Update user only if botThreadID is not already included
          console.log("Updating user with new chat ID", user, botThreadID);
          if (!chat_ids.includes(botThreadID)) {
            await updateUser(uuid, { chat_ids: [...chat_ids, botThreadID] });
          }
        }
      } catch (error) {
        console.error("Error in init function:", error);
      }
    }

    init();
  }, [botThreadID]);

  useEffect(() => {
    const fetchMessages = async () => {
      setInterval(updateUI, 200);
      const queryParams = new URLSearchParams(window.location.search);
      const message_id = queryParams.get("message_id");
      console.log(message_id);

      if (!botThreadID) {
        console.log("Adding bot message");
        if (bool_new_msg) handleAddResponseMessage(config.newMessage);
        bool_new_msg = false;
        return;
      }
      try {
        toggleMsgLoader();
        toggleInputDisabled();
        const response = await axios.get(
          `${REACT_APP_CHAT_BACKEND_BASE_URL}history`,
          {
            params: {
              threadID: botThreadID,
              limit: "50",
              apiKey: "None",
            },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = response.data;

        console.log("user data", data);

        // Uncomment if threadID management is needed
        // if ("threadID" in data && data.threadID) {
        //   setThreadID(data.threadID);
        //   localStorage.setItem("threadID", JSON.stringify(data.threadID));
        // }

        const history = data.history;

        if (history && history.length !== 0) {
          deleteMessages(1000);
          handleAddResponseMessage(config.newMessage);

          for (let i = 0; i < history.length; i++) {
            const message = history[i];

            if (message.role === "user") {
              // Add user message
              if (
                message.content?.length < 1000 &&
                !message?.content?.includes("first_name")
              )
                addUserMessage(message.content);
            } else if (
              message.role === "assistant" ||
              message.role === "system"
            ) {
              // Add assistant/system response
              // checks
              if (message.content?.length < 1000)
                handleAddResponseMessage(
                  parseResponse(message.content, false),
                  true
                );
            }
          }
          markAllAsRead();
        } else {
          handleAddResponseMessage(config.newMessage);
        }
      } catch (error) {
        // console.error('Error fetching messages:', error);
        // handleAddResponseMessage(config.errorMessage);
      } finally {
        if (message_id) {
          console.log(config.messages?.[message_id]);
          if (config.messages?.[message_id]) {
            console.log(config.messages?.[message_id]);
            handleAddResponseMessage(config.messages?.[message_id]);
            toggleWidget();
          }
        }
        toggleMsgLoader();
        toggleInputDisabled();
      }
    };

    fetchMessages();
    // Add initial welcome message
    //  addLinkSnippet({"link"})
  }, [botThreadID]);

  // useState(() => {
  //   const removeSimilarNeighbors = (arr) =>
  //     arr.filter(
  //       (item, index) => JSON.stringify(item) !== JSON.stringify(arr[index - 1])
  //     );

  //   const newMessages = removeSimilarNeighbors(messages);

  //   setMessages(newMessages); // Update state with the filtered messages
  // }, [messages]);

  const handleNewUserMessage = async (newMessage) => {
    toggleMsgLoader();
    setQuickButtons([]);
    toggleInputDisabled();

    // Update the state with the new user message
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "user", text: newMessage },
    ]);

    // Fetch response from the backend

    if (["Start WIC", "Start Lifeline", "Start SNAP"].includes(newMessage)) {
      // Check if any word in newMessage matches a key in linkDictionary
      const foundLink = Object.keys(linkDictionary).find((key) =>
        newMessage.toLowerCase().includes(key)
      );

      if (foundLink) {
        window.location.href = linkDictionary[foundLink];
      }
    } else {
      try {
        const response = await handleSendMessageToBackend(newMessage);
        let options = [];
        try {
          let staticOptions = {
            "/form/snap": { label: "▶️ Start SNAP", value: "Start SNAP" },
            "/form/wic": { label: "▶️ Start WIC", value: "Start WIC" },
            "/form/lifeline": {
              label: "▶️ Start Lifeline",
              value: "Start Lifeline",
            },
          };

          if (
            ["/form/snap", "/form/wic", "/form/lifeline"].some((opt) =>
              response.toLowerCase().includes(opt)
            )
          ) {
            options = Object.keys(staticOptions)
              .filter((key) => response.toLowerCase().includes(key))
              .map((key) => staticOptions[key]);
          } else {
            // const { data } = await axios.post(`${REACT_APP_CHAT_BACKEND_BASE_URL}simple_ai_query`, {
            //   instructions:
            //     "return one-two (short 1-2 words) responses completely opposite to each other to the statement in array of strings in json format. the responses should be simple as in a conversion regarding enrolling the user into benefits health program. don't return anything if the answers can only be thank you or got it etc.",
            //   query: response,
            // });
            // options = JSON.parse(getContentWithinBrackets(data.resp_text)).map((label) => ({ label, value: label }));
            if (response[response.length - 1] === "?")
              options = [
                { label: "Yes", value: "Yes" },
                { label: "No", value: "No" },
              ];
          }

          console.log(options);
        } catch (err) {
          console.error("Error in setting options", err);
        }

        // Parse and display the response message
        if (response) {
          handleAddResponseMessage(response);
          console.log("adding response", response, options);
          setQuickButtons(options);
        }
      } catch (error) {
        console.error("Error fetching response from backend:", error);
        handleAddResponseMessage(config.errorMessage);
      } finally {
        toggleInputDisabled();
        toggleMsgLoader();
      }
    }
  };

  const handleAddResponseMessage = (message, oldMessages = false) => {
    // add delete button

    // parse message
    message = parseResponse(message);
    if (typeof message === "string") {
      // If the message is a simple string, add it directly
      addResponseMessage(message);
    } else {
      // Check if the message has a response and add it
      if (message?.response) {
        addResponseMessage(message.response);
      }

      // Check if there are links and use addLinkSnippet
      if (message?.link) {
        message.link.forEach((link) => {
          addLinkSnippet(link);
        });
      }
      if (message?.actionButtons) {
        console.log(message.actionButtons);
        setQuickButtons(message.actionButtons);
      } else {
        setQuickButtons([]);
      }

      console.log(isWidgetOpened());
      if (!oldMessages && !isWidgetOpened()) {
        //  notificationSound.play()
      }
    }

    // Update the state with the new bot message
    setMessages((prevMessages) => {
      // if (
      //   prevMessages
      //     .filter((m) => m.type === "bot")
      //     .some((m) => m.text.response === message.response)
      // ) {
      //   console.log("prev", "not adding");
      //   return prevMessages;
      // }
      return [...prevMessages, { type: "bot", text: message }];
    });
  };

  const handleSendMessageToBackend = async (message) => {
    return fetchAIResponse(message, botThreadID, config, setThreadID);
  };

  const handleToggle = () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { type: "opened", text: "opened State" },
    ]);
    //  setIsOpened(!opened)
  };

  console.log("messages", messages);

  return (
    <div>
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        title={
          <div translate="no">
            Link Health
            <LanguageSelectionButton />
          </div>
        }
        chatId={"rcw-widget-container123"}
        subtitle="Ask questions or get enrolled into public benefits."
        handleToggle={handleToggle}
        profileAvatar="https://i0.wp.com/link-health.org/wp-content/uploads/2023/07/cropped-cropped-High-res-logo.webp?fit=150%2C150&ssl=1"
        profileClientAvatar="https://avatar.iran.liara.run/public/11"
        senderPlaceHolder="Ask something 🌟"
        titleAvatar="https://i0.wp.com/link-health.org/wp-content/uploads/2023/07/cropped-cropped-High-res-logo.webp?fit=150%2C150&ssl=1"
        showCloseButton={true}
        showTimeStamp={true}
        handleQuickButtonClicked={(v) => {
          addUserMessage(v);
          handleNewUserMessage(v);
        }}
        emojis={false}
        showBadge={false}
        resizable={false}
        autofocus={true}
        showChat={true}
        // fullScreenMode={true}
      />
    </div>
  );
};
