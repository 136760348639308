import { FaCheck } from "react-icons/fa6";
import { RiCircleLine, RiProgress3Line } from "react-icons/ri";
import { Button } from "antd";

// Define Ant Design buttons with icons
export const tableIcons = {
  FormSubmitted: <FaCheck style={{ color: "green" }} />,
  FormStarted: <RiProgress3Line style={{ color: "orange" }} />,
  FormOpened: <RiCircleLine style={{ color: "blue" }} />,
};

export const tableButtons = {
  FormSubmitted: (
    <Button type="link" icon={<FaCheck />} style={{ color: "green", borderColor: "green" }}>
      Submitted
    </Button>
  ),
  FormStarted: (
    <Button type="link" icon={<RiProgress3Line />} style={{ color: "orange", borderColor: "orange" }}>
      Started
    </Button>
  ),
  FormOpened: (
    <Button type="link" icon={<RiCircleLine />} style={{ color: "blue", borderColor: "blue" }}>
      Opened
    </Button>
  ),
};

export const Terminology = () => {
  return (
    <div>
      <h4>Terminology:</h4>
      <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
        <li>
          <span>1. {tableIcons.FormSubmitted}: Form Submitted</span>
        </li>
        <li>
          <span>2. {tableIcons.FormStarted}: Form Started but not completed</span>
        </li>
        <li>
          <span>3. {tableIcons.FormOpened}: Form link opened</span>
        </li>
      </ul>
    </div>
  );
};
