import React from 'react';
import { Button, Result } from 'antd';
import {  FaArrowRight } from 'react-icons/fa6';
import { RoughNotation } from 'react-rough-notation';

const ThankYouMessage = ({handleMainLinkClick=()=>{}}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
      <Result
        status="success"
        title="Thank You!"
        subTitle={<div style={{color:"black", fontSize:"17px"}}>We have received your information and will process it shortly. <br></br> <RoughNotation strokeWidth={4} color='yellow' type='highlight' show> <Button onClick={handleMainLinkClick} size='large' type='link'>Apply for more programs <FaArrowRight/> </Button></RoughNotation></div>}
      />
    </div>
  );
};

export default ThankYouMessage;
