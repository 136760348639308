export function addOrReplaceQueryParam(key, value) {
  if (value === null || value === undefined) return;
  const urlSearchParams = new URLSearchParams(window.location.search);

  // Check if the key is already present in the URL
  if (urlSearchParams.has(key)) {
    // If key is present, replace the existing value
    urlSearchParams.set(key, value);
  } else {
    // If key is not present, add it
    urlSearchParams.append(key, value);
  }

  const newUrl = `${window.location.pathname}?${urlSearchParams.toString()}${
    window.location.hash
  }`;
  window.history.replaceState(null, "", newUrl);
}

export function getAllParamsFromLocation() {
  const urlSearchParams = new URLSearchParams(window.location.search);

  const paramsObject = {};

  for (const [paramName, paramValue] of urlSearchParams) {
    paramsObject[paramName] = paramValue;
  }

  return paramsObject;
}

export function getBaseUrl(fullUrl = window.location.href) {
  const url = new URL(fullUrl);
  return url.origin;
}

export function clearLocalStorageExcept(
  keysToKeep = ["entrance_source", "languageSelected"]
) {
  // Store the current localStorage keys in a map
  const preservedData = keysToKeep.reduce((acc, key) => {
    if (localStorage.getItem(key) !== null) {
      acc[key] = localStorage.getItem(key);
    }
    return acc;
  }, {});

  // Clear all keys from localStorage
  localStorage.clear();

  // Restore the preserved keys and their values
  Object.entries(preservedData).forEach(([key, value]) => {
    localStorage.setItem(key, value);
  });
}
