// lifelineFormConfig.js

import fields from '../../fields';

export const formName= "lifeline-mbi";

export const lifelineMbiFormConfig = [
  { type: "heading", title: "Lifeline MBI Application" },
  {
    title: "Pick your clinic from the list",
    id: "clinic",
    required: true,
    type: "select",
    options: [
      { value: "EBNHC - East Boston Neighborhood Health Center", text: "EBNHC - East Boston Neighborhood Health Center" },
      { value: "South End Community Health Center", text: "South End Community Health Center" },
      { value: "Uphams Corner Health Center", text: "Uphams Corner Health Center" },
      { value: "Harvard Street Neighborhood Health Center", text: "Harvard Street Neighborhood Health Center" },
      { value: "Charles River Community Health Center", text: "Charles River Community Health Center" },
      { value: "Community Health Connections", text: "Community Health Connections" },
      { value: "Fenway Health", text: "Fenway Health" },
      { value: "Duffy Health Center", text: "Duffy Health Center" },
      { value: "Family Health Center of Worcester", text: "Family Health Center of Worcester" },
      { value: "Lowell Community Health Center", text: "Lowell Community Health Center" },
      { value: "North Shore Community Health Center", text: "North Shore Community Health Center" },
      { value: "SSTAR - Stanley Street Treatment and Resources", text: "SSTAR - Stanley Street Treatment and Resources" },
      { value: "Mashpee Wampanoag Tribe", text: "Mashpee Wampanoag Tribe" },
      { value: "Family Van", text: "Family Van" },
      { value: "Mobile Clinic", text: "Mobile Clinic" },
    ],
  },
  fields.first_name,
  fields.last_name,
  fields.dob,
  fields.preferred_language,
  fields.ssn_last4,
  fields.address,
  fields.city,
  fields.zip,
  fields.gender,
  fields.race,
  fields.email,
  {
    title: "How do you qualify?",
    id: "qualification",
    required: true,
    type: "checkbox",
    options: [
      { value: "SNAP", text: "SNAP" },
      { value: "Medicaid", text: "Medicaid" },
      { value: "Medicare", text: "Medicare" },
    ],
  },
  fields.phone_number,
  fields.consent(formName)
];
