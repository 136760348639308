import React from 'react';
import { List } from 'antd';

const data = [
  "I (or my dependent) receive the programs I listed above",
  "If I move I will give my internet company my new address within 30 days",
  "If I no longer receive the government program(s) I listed, I will tell my internet company",
  "I know that my household can only receive 1 Lifeline benefit",
  "The information provided can be collected, used, shared, and retained by the FCC",
  "I know that the Lifeline program is a federal government subsidy and at the program’s conclusion my rate will go back to what it was before",
  "All the answers provided are truthful",
  "I know that willingly giving false information to get Lifeline Program Benefits is punishable by law",
  "I know that the Lifeline administrator may have to check whether I qualify occasionally",
  "The address I used is accurate",
];

const SimpleList = () => (
  <List
    dataSource={data}
    renderItem={(item, index) => (
      <List.Item>
        {index + 1}. {item}
      </List.Item>
    )}
  />
);

export default SimpleList;
