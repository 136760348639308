import React from 'react';
import { Skeleton } from 'antd';

const Loading = ({ type = 'form', fields = 5 }) => {
  const randomSkeleton = () => {
    const randomField = Math.floor(Math.random() * 3); // 0: Input, 1: Button, 2: TextArea
    switch (randomField) {
      case 0:
        return <Skeleton.Input style={{ width: '100%', marginBottom: '20px' }} active />;
      case 1:
        return <Skeleton.Button style={{ width: '100%', marginBottom: '20px' }} active />;
      case 2:
        return <Skeleton.Input style={{ width: '100%', marginBottom: '20px', height: '60px' }} active />;
      default:
        return <Skeleton.Input style={{ width: '100%', marginBottom: '20px' }} active />;
    }
  };

  const renderSkeleton = () => {
    switch (type) {
      case 'form':
        return (
          <div style={{ padding: '10px', width: '100%', }}>
            {Array.from({ length: fields }).map((_, index) => (
              <div key={index}>{randomSkeleton()}</div>
            ))}
          </div>
        );
      default:
        return <Skeleton active />;
    }
  };

  return <div>{renderSkeleton()}</div>;
};

export default Loading;
