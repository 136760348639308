import React, { useState, useEffect } from "react";
import { Button, Card, Row, Col } from "antd";
import { Link, useNavigate } from "react-router-dom";
import formsConfig from "./pages/form/config";
import { toggleWidget } from "react-chat-widget";
import "./LandingPage.css";
import { RoughNotation } from "react-rough-notation";

const LandingPage = () => {
  const [chatStarted, setChatStarted] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const navigate = useNavigate();

  const startChat = () => {
    toggleWidget();
    setChatStarted(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowHeader(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = "https://i0.wp.com/link-health.org/wp-content/uploads/2024/02/Healthcare-sites-1.png?fit=1080%2C1080&ssl=1";
    img.onload = () => setImageLoaded(true);
  }, []);

  const handleNavClick = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      element.classList.add("highlight-section");
      setTimeout(() => element.classList.remove("highlight-section"), 500);
    }
  };

  return (
    <div className="landing-page">
      {/* Header Section */}
      {showHeader && (
        <header className="header">
          <img src="https://i0.wp.com/link-health.org/wp-content/uploads/2023/07/cropped-cropped-High-res-logo.webp?fit=150%2C150&ssl=1" alt="Link Health Logo" className="link-health-logo" />
          <nav className="navbar">
            <a href="#programs" onClick={() => handleNavClick("programs")}>
              Programs
            </a>
            <a href="#contact" onClick={() => handleNavClick("contact")}>
              Contact
            </a>
          </nav>
        </header>
      )}

      {/* Top Section */}
      <section className="top-section">
        <div className="content-overlay">
          <img src="https://i0.wp.com/link-health.org/wp-content/uploads/2023/07/cropped-cropped-High-res-logo.webp?fit=150%2C150&ssl=1" alt="Link Health Logo" className="link-health-logo" />
          <h1 className="title">
            Chat with <RoughNotation type="circle" animate> Link Health</RoughNotation>
          </h1>
          <p className="intro-text">Every year, 103 million Americans leave $80 billion in benefits unclaimed. We're here to help you claim what you deserve.</p>
          {
            <Button type="primary" size="large" className="start-chat-btn" onClick={startChat}>
              Start Chat
            </Button>
          }
        </div>
      </section>

      {/* Programs Section */}
      <section id="programs" className="programs-section section">
        <h2>Explore Our Programs</h2>
        <p>Browse through various programs you may qualify for. Our chat system will guide you through each step to make the process simple and effective.</p>
        <Row gutter={[16, 16]}>
          {Object.keys(formsConfig).map((key) => (
            <Col style={{ display: formsConfig[key].displayInHomePage ? "" : "none" }} key={key} xs={24} sm={12} md={8} onClick={() => navigate("/form/" + key)}>
              <Card className="program-card" hoverable>
                <h3>{formsConfig[key].title}</h3>
                <p>Learn more about the {formsConfig[key].title} program and how it can benefit you.</p>
                <Button type="primary" className="start-btn">
                  Start
                </Button>
              </Card>
            </Col>
          ))}
        </Row>
      </section>

      {/* Contact Section */}
      <section id="contact" className="contact-section section">
        <h2>Contact Us</h2>
        <p>Have questions or need assistance? Our team is here to help. Reach out to us, and we'll get back to you promptly.</p>
        <p>
          Email: <a href="mailto:support@linkhealth.org">support@linkhealth.org</a>
        </p>
      </section>

      {/* Footer Section */}
      <footer className="footer">
        <p>&copy; 2024 Link Health AI. All Rights Reserved.</p>
        <p>
          Follow us: <a href="#">Facebook</a> | <a href="#">Twitter</a> | <a href="#">Instagram</a>
        </p>
      </footer>
    </div>
  );
};

export default LandingPage;
