import React from "react";
import formsConfig from "../form/config";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // For tick and cross icons
import "./Profile.css"; // Import the CSS file for styling

const Profile = ({ user }) => {
  if (!user) return null;

  return (
    <div className="profile-container">
      <div className="profile-header">
        Hello, {user.first_name} {user.last_name}
      </div>
      <div className="forms-list">
        <div className="forms-list-header">Your Applications Status</div>
        {Object.keys(formsConfig).map((key) => (
          <div
            key={key}
            className="form-item"
            style={{ display: !user[`${key}_submitted`] ? "none" : "" }}
          >
            <span className="form-title" translate="no">
              {formsConfig[key].title}
            </span>
            {user[`${key}_submitted`] ? (
              <>
                Submitted
                <FaCheckCircle className="tick-icon" />
              </>
            ) : (
              <a href={`/form/${key}`} className="form-link">
                <FaTimesCircle className="cross-icon" /> Submit Now
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Profile;
