export const getLsId = (key = "uuid", n = 10) => {
  // Check if ID already exists in localStorage
  let existingId = localStorage.getItem(key);

  if (existingId) {
    return existingId;
  }

  const randString = getRandomString(n);
  // Save the new ID in localStorage
  localStorage.setItem(key, randString);

  return randString;
};

export const getRandomString = (n=10) => {
  // Generate new ID if not found in localStorage
  let rnd = "";
  for (let i = 0; i < n; i++) {
    const ch = Math.floor(Math.random() * 10); // Changed to generate digits only (0-9)
    rnd += ch;
  }
  return rnd;
};
