import React, { useEffect, useState } from "react";
import { getAllUsers } from "../../../backend/users/get";
import Loading from "../../../components/ui/loading/Loading";
import UserTable from "./components/UserTable";
import {Terminology} from "./components/static";
import { routeMap } from "../../../routes";
import { Button, Modal, Typography, Divider, Row, Col, Card, Space } from "antd";
import { useAtom } from "jotai";
import { rawUsersAtom, usersAtom } from "./users.jotai";

const { Title, Link, Text } = Typography;

const AdminDashboard = () => {
  const [users, setUsers] = useAtom(usersAtom);
  const [rawUsers, setRawUsers] = useAtom(rawUsersAtom);

  useEffect(() => {
    getAllUsers().then((users) => setUsers(users));
    getAllUsers("Raw").then((users) => setRawUsers(users));
  }, []);

  return (
    <main style={{ padding: "14px", backgroundColor: "#f0f2f5" }}>
      <Card style={{ padding: "10px", borderRadius: "8px", boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)" }}>
        <Title level={1} style={{ marginBottom: "16px", textAlign: "center" }}>
          Admin Dashboard
        </Title>

        {/* Links Section */}
        <section>
          <Title level={2} style={{ marginBottom: "12px" }}>
            Links
          </Title>
          <ul style={{ listStyleType: "none", padding: 0, marginLeft: "20px" }}>
            {routeMap.map(
              (route, index) =>
                route.path.includes("admin") &&
                !route.path.includes("login") && (
                  <li key={index} style={{ marginBottom: "8px" }}>
                    <Link href={route.path} style={{ textTransform: "capitalize" }}>
                      {route.path.split("/")[2]}
                    </Link>
                  </li>
                )
            )}
          </ul>
        </section>

        <Divider />

        {/* User Table Section */}
        <section>
          <Row align="middle" justify="space-between" style={{ marginBottom: "20px" }}>
            <Title level={2}>Submissions</Title>
            <Button
              type="primary"
              onClick={() => {
                Modal.info({
                  title: "Terminology",
                  content: <Terminology />,
                  width: 600,
                  centered: true,
                });
              }}
            >
              View Terminology
            </Button>
          </Row>

          <Card
            bordered={false}
            style={{
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
              padding: "20px",
            }}
          >
            {users && rawUsers ? <UserTable users={users} rawUsers={rawUsers} /> : <Loading />}
          </Card>
        </section>
      </Card>
    </main>
  );
};

export default AdminDashboard;
