// lifelineFormConfig.js

import fields from '../../fields';
export const formName = "lifeline-inperson";
export const lifelineInPersonFormConfig = [
  { type: "heading", title: "Lifeline Application" },

  fields.first_name,
  fields.last_name,
  fields.dob,
  fields.preferred_language,
  fields.ssn_last4,
  fields.address,
  fields.city,
  fields.zip,
  fields.gender,
  fields.race,
  fields.email,
  fields.phone_number,
  {
    title: "How do you qualify?",
    id: "qualification",
    required: true,
    type: "checkbox",
    options: [
      { value: "SNAP", text: "SNAP" },
      { value: "Medicaid", text: "Medicaid" },
      { value: "Medicare", text: "Medicare" },
    ],
  },
  fields.consent(formName),
];
