// RequireAuth.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const RequireAuth = ({ children }) => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser !== undefined) {
      setLoading(false);
    }
  }, [currentUser]);

  if (loading) {
    return <div>checking permissions...</div>; // Only show loading state for protected routes
  }

  if (!currentUser) {
    return <Navigate to="/admin/login" />;
  }

  return children;
};

export default RequireAuth;
