function getRandomImage() {
    const images = [
        "https://i0.wp.com/link-health.org/wp-content/uploads/2024/04/Link-Health-Team-2.png?fit=1080%2C1080&ssl=1",
        "https://i0.wp.com/link-health.org/wp-content/uploads/2024/06/LH-Main-photo.png?fit=1080%2C1080&ssl=1",
        "https://i0.wp.com/link-health.org/wp-content/uploads/2022/10/Link-main.png?fit=441%2C325&ssl=1"
    ];

    // Select a random image
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
}

export default getRandomImage
