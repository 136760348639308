import React from "react";

// Parsing function to clean and parse the response text
export const parseAISpamResponseData = (responseText) => {
  try {
    // Remove markdown code fencing and extra characters to get valid JSON
    const cleanText = responseText.replace(/(^json\n|^```json|```$|\n)/gm, "");
    return JSON.parse(cleanText);
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return null;
  }
};

const DisplayResponse = ({ response }) => {
  const parsedData = parseAISpamResponseData(response.resp_text);

  if (!parsedData) {
    return <p>Error parsing response.</p>;
  }

  return (
    <div>
      <h2>Classification: {parsedData.classification}</h2>
      <hr />
      <p>
        <strong>Confidence:</strong>
        <span style={{ color: "red" }}> {parsedData.fakeness_level}% fake</span> and
        <span style={{ color: "blue" }}> {parsedData.realness_level}% real</span>
      </p>
      <p>
        <strong>Comments:</strong> {parsedData.comments}
      </p>
      {/* <pre style={{ backgroundColor: '#f6f8fa', padding: '10px', borderRadius: '5px' }}>
        {JSON.stringify(parsedData, null, 2)}
      </pre> */}
    </div>
  );
};

export default DisplayResponse;
