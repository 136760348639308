export function wrapValueInObject(obj) {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (typeof obj[key] === "object") newObj[key] = value;
      else newObj[key] = { value: value };
    }
    return newObj;
  }
  
  
  export function flattenJson(data) {
    const flattenedData = {};
    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            flattenedData[key] = data[key] && typeof data[key] === 'object' && 'value' in data[key] ? data[key].value : data[key];
        }
    }
    return flattenedData;
  }
  
  export function unflattenJson(obj) {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (typeof obj[key] === "object") newObj[key] = value;
      else newObj[key] = { value: value };
    }
    return newObj;
  }
  
  
  
  export function convertListToObject(list) {
    return list.reduce((obj, item) => {
      obj[item.key] = item.value;
      return obj;
    }, {});
  }
  
  export function cleanObject(obj, clean=["", null, undefined]) {
    const invalidChars = /[~*/[\]]/;  // Regular expression to match invalid characters
    return Object.keys(obj).reduce((acc, key) => {
      if (!clean.includes(obj[key]) && !invalidChars.test(key)) {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  }
  