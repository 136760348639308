import React, { useState, useEffect, useRef } from "react";
import { Button } from "antd";
import { RiTranslate2 } from "react-icons/ri";

const LanguageSelection = ({
  showLanguageSelector,
  setShowLanguageSelector,
}) => {
  const languageSelectorRef = useRef(null);

  useEffect(() => {
    // Check if the language selection is already done
    const isLanguageSelected = localStorage.getItem("languageSelected");
    if (!isLanguageSelected) {
      setShowLanguageSelector(true);
    }
  }, []);

  useEffect(() => {
    if (showLanguageSelector) {
      const gtElement = document.getElementById("gt");
      if (gtElement) {
        // Move the `gt` element into the modal
        languageSelectorRef.current.appendChild(gtElement);
      }
    }
  }, [showLanguageSelector]);

  const handleProceed = () => {
    localStorage.setItem("languageSelected", "true");
    setShowLanguageSelector(false);
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: showLanguageSelector ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f0f2f5",
          zIndex: 99999,
          marginBottom: "10px",
          color: "black",
        }}
      >
        <img src="https://i0.wp.com/link-health.org/wp-content/uploads/2023/07/cropped-cropped-High-res-logo.webp?fit=150%2C150&ssl=1"></img>
        <h3>
          <RiTranslate2 />
          Select your Language
        </h3>
        <div
          ref={languageSelectorRef}
          style={{ zoom: 1.7, marginBottom: "10px" }}
        ></div>
        <Button type="primary" onClick={handleProceed}>
          Proceed
        </Button>
      </div>
    </>
  );
};

export const LanguageSelectionButton = ({ triggerBtn = true }) => {
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);

  return (
    <div>
      <LanguageSelection
        showLanguageSelector={showLanguageSelector}
        setShowLanguageSelector={setShowLanguageSelector}
      />
      {triggerBtn ? (
        <Button
          onClick={() => {
            localStorage.removeItem("languageSelected");
            const gtElement = document.getElementById("gt");
            const originalParent =
              document.getElementById("conditionalContent"); // Replace with the actual parent ID or container
            if (gtElement && originalParent) {
              // Move the `gt` element back to its original parent or a safe container
              originalParent.appendChild(gtElement);
            }
            setTimeout(() => {
              setShowLanguageSelector(true);
            }, 100);
          }}
        >
          <RiTranslate2 /> Language{" "}
        </Button>
      ) : null}
    </div>
  );
};

export default LanguageSelection;
