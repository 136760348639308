import { Plucky } from 'plucky-js';

const plucky = new Plucky({
  apiKey: 'a11e4a99-0ef1-4930-850e-924735160b67'
});

// Function to create a case
export const createPluckyCase = (name, type, externalId) => {
  plucky.capture({
    type: "case",
    case: {
      name: name,
      type: type,
      externalId: externalId,
    }
  });
};

// Function to create an event
export const createPluckyEvent = (name, details, caseExternalId) => {
  plucky.capture({
    type: "event",
    event: {
      name: name,
      details: details,
      caseExternalId: caseExternalId,
    }
  });
};